.about {
    width: 100%;
    height: 100vh;
    position: relative;
    background: rgba(0, 0, 0, .3);
}

.about:before {
    content: '';
    position: absolute;
    background: url('../../assets/cloud-bg.png') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
}

.about .content {
    position: absolute;
    left: 0;
    top: 15%;
    width: 50%;
    padding: 1rem;
}

.about h2 {
    color: #f8f8f8;
}

.about h1{
    color: #fff;
}

.about p {
    color: #f8f8f8;
    margin: 1rem 0;
}

@media screen and (max-width: 940px) {
    .cloud .content {
        left: 0;
        width: 100%;
    }
}