

/* STRUCTURE */

.wrapper {
	padding: 5px;
	max-width: 1000px;
	width: 100%;
	margin: 20px auto;
}
.services-subtitle h2{
 font-size: 40px ;
}

.services-columns {
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
	margin: 5px 0;
  box-shadow: 5px 10px;
}

.services-column {
	flex: 1;
	border: 1px solid rgb(0, 0, 0);
	margin: 2px;
	padding: 10px;
	&:first-child { margin-left: 0; }
	&:last-child { margin-right: 0; }
	
}



@media screen and (max-width: 980px) {
  .services-columns .services-column {
		margin-bottom: 5px;
    flex-basis: 40%;
		&:nth-last-child(2) {
			margin-right: 0;
		}
		&:last-child {
			flex-basis: 100%;
			margin: 0;
		}
	}
}
.services-title{
  text-align: center;

}
@media screen and (max-width: 680px) {
	.services-columns .services-column {
		flex-basis: 100%;
		margin: 0 0 5px 0;
	}
}